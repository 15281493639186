$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
/* Layouts */

@import "../../../../../../node_modules/bootstrap/scss/functions";
@import "./../../../../../../assets/scss/starAdminVariables"; // No path: it will be set from webpack.config.js so it can be overridden.
@import "../shared/variables";
@import "../../../../../../node_modules/bootstrap/scss/variables";
@import "./variables";

@import "../shared/mixins/misc";
@import "../shared/mixins/animation";
@import "./nav";
@import "./navbar";
@import "./sidebar";
@import "./footer";
@import "./layouts";
@import "./card";
@import "./misc";
@import "./dashboard";

@import "./components/modals";
@import "./components/full-calendar";
